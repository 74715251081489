import { useAssets, useMobile } from "../../../../hooks";
import { CustomButton } from "../../../../components/CustomButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";

const ServiceCard = ({
  service,
  buttonType,
  setCurrent,
  setIsOpen,
  isPurchase,
  setIsPurchase,
  allService,
  url,
  isFirst = false,
}) => {
  const navigator = useNavigate();
  const pathname = useLocation().pathname;
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const myservice = isPurchase ? service.service : service;
  const types = !isPurchase
    ? myservice.types
        .map((t, i) => {
          if (i !== 0) return ", " + t.serviceType.title;
          else return t.serviceType.title;
        })
        .toString()
    : allService.filter((s) => {
        return s.id == myservice.id;
      })[0]
    ? allService
        .filter((s) => {
          return s.id == myservice.id;
        })[0]
        .types.map((t, i) => {
          if (i !== 0) return t.serviceType.title;
          else return t.serviceType.title;
        })
        .toString()
    : "";
  return (
    <div id={service.id} className="servic-card d-flex flex-column">
      <LazyLoadImage
        effect="blur"
        style={
          isMobile
            ? { minHeight: "120px", maxHeight: "120px", objectFit: "cover" }
            : { minHeight: "150px", maxHeight: "150px", objectFit: "cover" }
        }
        width={"100%"}
        placeholderSrc={getFile("loading")}
        src={myservice.cover ? url + "/" + myservice.cover : getFile("noImg")}
      />
      <div
        className={
          isMobile
            ? "px-2 py-2 d-flex flex-column gap-3"
            : "px-4 py-3 d-flex flex-column gap-3"
        }
      >
        <div className="d-flex flex-row">
          <h4
            style={
              isMobile
                ? {
                    width: "80%",
                    fontSize: "12px",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                  }
                : { width: "92%" }
            }
          >
            {!isMobile
              ? types.length > 23
                ? types.substr(0, 21) + " ..."
                : types
              : types.length > 16
              ? types.substr(0, 14) + " ..."
              : types}
          </h4>

          <div
            dir="rtl"
            style={
              isMobile ? { width: "20%", marginLeft: "20px" } : { width: "8%" }
            }
          ></div>
        </div>
        <h2
          title={myservice.title}
          style={
            isMobile
              ? { fontSize: "14px", margin: 0, padding: 0 }
              : { fontSize: "18px" }
          }
        >
          {!isMobile
            ? myservice.title.length > 75
              ? myservice.title.substr(0, 75) + "..."
              : myservice.title
            : myservice.title.length > 75
            ? myservice.title.substr(0, 75) + "..."
            : myservice.title}
        </h2>
        {myservice.activeDays && myservice.activeDays > 1 && (
          <div className="d-flex flex-row gap-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 6.66713e-05C3.58969 6.66713e-05 0 3.58974 0 8.00003C0 12.4103 3.58969 16 8 16C12.4103 16 16 12.4103 16 8.00003C16 5.24023 14.5986 2.80016 12.4661 1.36204L13.2305 0.381575L10 0.264389L10.9323 3.33339L11.6406 2.42323C13.2849 3.49817 14.4262 5.27285 14.6302 7.33467C14.46 7.34399 14.2999 7.41815 14.1826 7.5419C14.0654 7.66564 14.0001 7.82959 14 8.00003C14.0001 8.17112 14.066 8.33561 14.1841 8.45946C14.3021 8.58331 14.4632 8.65704 14.6341 8.66539C14.3229 11.8216 11.8216 14.3229 8.66537 14.6341C8.65701 14.4632 8.58328 14.3021 8.45943 14.1841C8.33558 14.066 8.17109 14.0001 8 14C7.82891 14.0001 7.66442 14.066 7.54057 14.1841C7.41672 14.3021 7.34299 14.4632 7.33464 14.6341C4.17837 14.3229 1.67714 11.8216 1.36589 8.66539C1.53677 8.65704 1.6979 8.58331 1.81593 8.45946C1.93397 8.33561 1.99987 8.17112 2 8.00003C1.99987 7.82895 1.93397 7.66446 1.81593 7.54061C1.6979 7.41676 1.53677 7.34303 1.36589 7.33467C1.69875 3.95935 4.53519 1.33339 8 1.33339C8.08834 1.33464 8.17605 1.31832 8.25803 1.28538C8.34001 1.25244 8.41462 1.20353 8.47754 1.1415C8.54045 1.07947 8.59041 1.00556 8.6245 0.924053C8.6586 0.842549 8.67616 0.75508 8.67616 0.666731C8.67616 0.578381 8.6586 0.490912 8.6245 0.409408C8.59041 0.327903 8.54045 0.253989 8.47754 0.191959C8.41462 0.12993 8.34001 0.0810228 8.25803 0.0480805C8.17605 0.0151381 8.08834 -0.00118269 8 6.66713e-05ZM7.98958 3.32427C7.813 3.32703 7.64473 3.39974 7.52169 3.52643C7.39866 3.65313 7.33092 3.82346 7.33333 4.00005V7.6667L4.93359 9.46617C4.79201 9.57219 4.69834 9.73011 4.67318 9.90519C4.64803 10.0803 4.69346 10.2582 4.79948 10.3998C4.9055 10.5413 5.06342 10.635 5.2385 10.6602C5.41358 10.6853 5.59149 10.6399 5.73307 10.5339L8.39974 8.53389C8.48265 8.47176 8.54994 8.39116 8.59628 8.2985C8.64261 8.20583 8.66671 8.10364 8.66667 8.00003V4.00005C8.66789 3.91086 8.6512 3.82233 8.61759 3.7397C8.58398 3.65707 8.53413 3.58203 8.47099 3.51902C8.40786 3.456 8.33272 3.4063 8.25003 3.37285C8.16734 3.3394 8.07877 3.32288 7.98958 3.32427Z"
                fill="#5F5F5F"
              />
            </svg>
            <h3>{myservice.activeDays} Days</h3>
          </div>
        )}
        <CustomButton
          title={buttonType}
          uiType="outlined"
          additionalClassNames={"fixButton"}
          // fullWidth
          onClick={() => {
            setCurrent(service);
            setIsPurchase(isPurchase);
            if (isPurchase) {
              navigator(
                pathname.replace("service", "purchase") + "/" + service.id
              );
            } else {
              navigator(pathname + "/" + service.id);
            }
            setIsOpen(true);
          }}
        />
      </div>
    </div>
  );
};
export default ServiceCard;
